import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './defaults.sass';
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import EnvironmentBanner from "./components/EnvironmentBanner";


ReactDOM.render(
    // <React.StrictMode>
    <BrowserRouter>
        <EnvironmentBanner />
        <App/>
    </BrowserRouter>,
    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
