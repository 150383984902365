import React, {Component, Suspense} from 'react';
import {addNotification, Notification, NotificationComponent} from "./modules/notifications/notifications";
import './App.sass';
import {Icon} from "semantic-ui-react";
import SideBar from "./components/sidebar/SideBar";
import ErrorBoundary from "./components/ErrorBoundary";
import {Switch, Route, withRouter, RouteComponentProps, RouteProps} from "react-router-dom";
import {View} from "./models";
import views, {createUrl} from "./views";
import {api, throwErrorsIfNotOk} from "./api";
import {JsonResponse, JsonResponseType} from "./scripts/apiModels";
import auth, {AuthUser} from "./auth";
import {BreakChainError, userHasRoles} from "./utils";
import {rollbar} from "./Rollbar";
import LoadingSplash from "./components/LoadingSplash";

interface ComponentProps extends RouteComponentProps {
}

interface ComponentState extends RouteProps {
    isLoadingUserInfo: boolean
}

export class App extends Component<ComponentProps, ComponentState> {

    static isNightTime() {
        const now = new Date();
        return now.getHours() < 7 || now.getHours() >= 19
    }

    private readonly errorBoundary: React.RefObject<ErrorBoundary>;
    private _isMounted: boolean;
    private static _instance: App;

    static getInstance() {
        return App._instance;
    }

    constructor(props: ComponentProps) {
        super(props);
        this._isMounted = false;
        App._instance = this;

        this.errorBoundary = React.createRef();

        this.state = {
            isLoadingUserInfo: false,
        }

        this.getViewFromUrl = this.getViewFromUrl.bind(this);
        this.setView = this.setView.bind(this);
        this.SetupForCurrentView = this.SetupForCurrentView.bind(this);
        this.getUserInformation = this.getUserInformation.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getUserInformation();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    private getViewFromUrl() {
        const path = this.props.location.pathname;
        return Object.values(views).find(it => it.url === path)
    }

    setView(view: View, params?: Object) {
        this.setUrl(createUrl(view, params));
    }

    setUrl(url?: string) {
        if (url === undefined) {
            rollbar.error("Cannot set App url to undefined");
            return;
        }

        this.props.history.push(url)
        this.errorBoundary.current?.reset()
    }

    private getUserInformation() {
        if (!this._isMounted) return;
        this.setState({isLoadingUserInfo: true})

        api.auth.users.me()
            .then(response => {
                if (!this._isMounted) return Promise.reject(BreakChainError());

                if (response.status !== 401) {
                    return response
                }

                auth.isLoggedIn = false
                auth.user = undefined

                this.setState({
                    isLoadingUserInfo: false,
                })
                return Promise.reject(BreakChainError());
            })
            .then(throwErrorsIfNotOk)
            .then(response => response.json())
            .then((data: JsonResponse) => {
                if (!this._isMounted) return;

                if (data.type === JsonResponseType.ERROR) {
                    throw new Error(data.content);
                }

                auth.isLoggedIn = true
                auth.user = new AuthUser({
                    id: data.content.id,
                    username: data.content.username,
                    name: data.content.name,
                    roles: data.content.roles,
                })

                this.setState({
                    isLoadingUserInfo: false,
                })
            })
            .catch(error => {
                if (error.name === "TypeError" && error.message === "NetworkError when attempting to fetch resource.") {
                    throw new Error("Server is unreachable.")
                } else if (error.name === "SyntaxError" && error.message === "Unexpected token < in JSON at position 0") {
                    throw new Error("Server is unreachable.")
                }

                throw error
            })
            .catch(error => {
                if (!this._isMounted) return;
                if (error.name === BreakChainError().name) {
                    return;
                }

                rollbar.error(`Error fetching user information`, error);
                addNotification(new Notification("Error fetching user information",
                    error.message,
                    Notification.ERROR));

                this.setState({isLoadingUserInfo: false})
            });
    }

    private initTheme() {
        const theme = App.isNightTime() ? "theme-dark" : "theme-light";
        document.body.classList.remove("theme-dark");
        document.body.classList.remove("theme-light");
        document.body.classList.add(theme);
    }

    private setDocumentTitle(view: View) {
        document.title = "jSchedule";
        if (view.title) {
            document.title = `${view.title} - ${document.title}`;
        }
    }

    private SetupForCurrentView() {
        return <Switch>
            {Object.values(views)
                .filter(view => view.needsAuthentication ? auth.isLoggedIn : true)
                .filter(view => userHasRoles(view.permissions))
                .map(view =>
                    <Route path={view.url}
                           key={view.url}
                           exact={view.exactPath}
                           render={() => {
                               this.setDocumentTitle(view);
                               return null
                           }}/>)}
        </Switch>
    }

    render() {
        this.initTheme();

        if (this.state.isLoadingUserInfo) {
            return <div className={"App"}>
                <NotificationComponent/>
                <h3 style={{textAlign: "center", marginTop: "25vh"}}>
                    <Icon loading name="circle notched"/>
                    Getting things ready...
                </h3>
            </div>;
        }

        return <div className={"App"}>
            <NotificationComponent/>
            <this.SetupForCurrentView/>

            <div className={"wrapper"}>
                <ErrorBoundary ref={this.errorBoundary}>
                    <Suspense fallback={<LoadingSplash isLoading={true}
                                                       text={"Loading website..."}
                                                       title={"Loading content..."}/>}>
                        <Switch>
                            <Route exact={views.HomePage.exactPath}
                                   path={views.HomePage.fullPath}
                                   component={views.HomePage.component}
                                   render={views.HomePage.render}
                            />
                            <Route exact={views.PrivacyPolicy.exactPath}
                                   path={views.PrivacyPolicy.fullPath}
                                   component={views.PrivacyPolicy.component}
                                   render={views.PrivacyPolicy.render}
                            />
                            <Route path={"*"}>
                                <SideBar setView={this.setView}/>

                                <Suspense fallback={<LoadingSplash isLoading={true}
                                                                   text={"Loading page..."}
                                                                   title={"Loading content..."}/>}>
                                    <div className={'content'}>
                                        <Switch>
                                            {Object.values(views)
                                                .filter(view => view.needsAuthentication ? auth.isLoggedIn : true)
                                                .filter(view => userHasRoles(view.permissions))
                                                .map(view =>
                                                    <Route exact={view.exactPath}
                                                           key={view.url}
                                                           path={view.url}
                                                           component={view.component}
                                                           render={view.render}/>
                                                )}
                                        </Switch>
                                    </div>
                                </Suspense>
                            </Route>
                        </Switch>
                    </Suspense>
                </ErrorBoundary>
            </div>
        </div>;
    }
}

export default withRouter(App);
