import {api, throwErrorsIfNotOk} from "../../api";
import {JsonResponse, JsonResponseType} from "../apiModels";
import {rollbar} from "../../Rollbar";

export interface ServerApplicationDetails {
    version: string
    artifactId: string
    name: string
}

export const getServerApplicationDetails = ({onSuccess, onError}: {
    onSuccess?: (details: ServerApplicationDetails) => void,
    onError?: (error: Error) => void,
} = {}) => {
    api.server.applicationDetails()
        .then(throwErrorsIfNotOk)
        .then(response => response.json())
        .then((data: JsonResponse) => {
            if (data.type === JsonResponseType.ERROR) {
                throw new Error(data.content);
            }

            onSuccess?.(data.content)
        })
        .catch(error => {
            rollbar.error(`Error fetching server application details`, error);
            onError?.(error)
        });
}
