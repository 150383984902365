import React, {Component} from 'react';
import {Icon, StrictIconProps} from "semantic-ui-react";
import {Route} from "react-router-dom";
import {View} from "../../models";
import views, {createUrl} from "../../views";
import {userHasRoles} from "../../utils";
import SideBarItem from "./SideBarItem";
import "./SideBar.sass";
import UserItem from "./UserItem";
import auth from "../../auth";
import AppBuildInformation from "./AppBuildInformation";

interface ComponentProps {
    setView: (view: View) => void
}

interface ComponentState {
}

export default class SideBar extends Component<ComponentProps, ComponentState> {

    constructor(props: ComponentProps) {
        super(props);

        this.state = {};

        this.setView = this.setView.bind(this);
    }

    setView(e: React.MouseEvent, view: View) {
        e.preventDefault();
        this.props.setView(view);
    }

    render() {
        return <div className={"SideBar"}>
            <div className={"content"}>
                <a className={"SideBarItem title"}
                   href={createUrl(views.HomePage)}
                   onClick={(e) => this.setView(e, views.HomePage)}>
                    <Icon name='home'/>
                    <span>jSchedule</span>
                </a>

                {Object.values(views)
                    .filter(view => view.showInMenu)
                    .filter(view => view.needsAuthentication ? auth.isLoggedIn : true)
                    .filter(view => userHasRoles(view.permissions))
                    .map(view =>
                        <Route path={view.url}
                               key={view.url}
                               exact={view.exactPath}
                               children={({match}) =>
                                   <SideBarItem
                                       key={view.title}
                                       active={!!match}
                                       href={createUrl(view)}
                                       onClick={(e: React.MouseEvent) => this.setView(e, view)}>
                                       <Icon name={view.icon as keyof StrictIconProps["name"]}/>
                                       <span>{view.title}</span>
                                   </SideBarItem>
                               }/>
                    )}
            </div>

            <div className={"footer"}>
                <UserItem/>

                <AppBuildInformation/>
            </div>
        </div>;
    }
}
