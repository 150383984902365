import React, {Component} from 'react';
import auth from "../../auth";
import SideBarItem from "./SideBarItem";
import {api} from "../../api";
import {Icon} from "semantic-ui-react";
import views from "../../views";
import {App} from "../../App";

interface ComponentProps {
}

interface ComponentState {
}

export default class UserItem extends Component<ComponentProps, ComponentState> {

    constructor(props: ComponentProps) {
        super(props);

        this.state = {};
    }

    render() {
        if (!auth.isLoggedIn) {
            return <div className={"UserItem"}>
                <SideBarItem onClick={() => App.getInstance().setView(views.LoginScreen)}>
                    <Icon name='sign-in'/>
                    <span>Log in</span>
                </SideBarItem>
            </div>;
        }
        return <div className={"UserItem"}
                    title={`Logged in as: ${auth.user?.name} (${auth.user?.username})`}>
            <SideBarItem onClick={api.auth.logout}>
                <Icon name='sign-out'/>
                <span>Log out</span>
            </SideBarItem>
        </div>;
    }
}
