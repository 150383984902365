export enum UserRole {
    ACCESS_REQUESTS_VIEW = "ACCESS_REQUESTS_VIEW",
    ACCESS_REQUESTS_CHANGE = "ACCESS_REQUESTS_CHANGE",
    USERS_VIEW = "USERS_VIEW",
    USERS_CHANGE = "USERS_CHANGE",
    SCHEDULES_VIEW = "SCHEDULES_VIEW",
    SCHEDULES_CHANGE = "SCHEDULES_CHANGE",
    SCHEDULE_ITEMS_VIEW = "SCHEDULE_ITEMS_VIEW",
    SCHEDULE_ITEMS_CHANGE = "SCHEDULE_ITEMS_CHANGE",
    SCHEDULE_VERSES_VIEW = "SCHEDULE_VERSES_VIEW",
    SCHEDULE_VERSES_CHANGE = "SCHEDULE_VERSES_CHANGE",
    ACTIVITY_VIEW = "ACTIVITY_VIEW",
    SONG_BUNDLES_VIEW = "SONG_BUNDLES_VIEW",
    SONG_BUNDLES_CHANGE = "SONG_BUNDLES_CHANGE",
    SONGS_VIEW = "SONGS_VIEW",
    SONGS_CHANGE = "SONGS_CHANGE",
    SONG_VERSES_VIEW = "SONG_VERSES_VIEW",
    SONG_VERSES_CHANGE = "SONG_VERSES_CHANGE",
    ABC_MELODIES_VIEW = "ABC_MELODIES_VIEW",
    ABC_MELODIES_CHANGE = "ABC_MELODIES_CHANGE",
    SONG_AUDIOS_VIEW = "SONG_AUDIOS_VIEW",
    SONG_AUDIOS_CHANGE = "SONG_AUDIOS_CHANGE",

    BIBLES_VIEW = "BIBLES_VIEW",
    BIBLES_CHANGE = "BIBLES_CHANGE",
    BOOKS_VIEW = "BOOKS_VIEW",
    BOOKS_CHANGE = "BOOKS_CHANGE",
    CHAPTERS_VIEW = "CHAPTERS_VIEW",
    CHAPTERS_CHANGE = "CHAPTERS_CHANGE",
    SCRIPTURE_VERSES_VIEW = "SCRIPTURE_VERSES_VIEW",
    SCRIPTURE_VERSES_CHANGE = "SCRIPTURE_VERSES_CHANGE",

    DOCUMENT_GROUPS_VIEW = "DOCUMENT_GROUPS_VIEW",
    DOCUMENT_GROUPS_CHANGE = "DOCUMENT_GROUPS_CHANGE",
    DOCUMENTS_VIEW = "DOCUMENTS_VIEW",
    DOCUMENTS_CHANGE = "DOCUMENTS_CHANGE",

    SYSTEM_DETAILS_VIEW = "SYSTEM_DETAILS_VIEW",
}

interface UserProps {
    id: Number
    username: string
    name: string
    roles: Array<UserRole>
}

export class AuthUser {
    id: Number
    username: string
    name: string
    roles: Array<UserRole>

    constructor(props: UserProps) {
        this.id = props.id
        this.username = props.username
        this.name = props.name
        this.roles = props.roles
    }
}

interface Auth {
    isLoggedIn: boolean
    user?: AuthUser
}

const auth = {
    isLoggedIn: false,
    user: undefined,
} as Auth

export default auth;
