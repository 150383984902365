import React, {Component} from 'react';
import './AppBuildInformation.sass';
import {getServerApplicationDetails, ServerApplicationDetails} from "../../scripts/server/common";

interface ComponentProps {
}

interface ComponentState {
    serverApplicationDetails?: ServerApplicationDetails
}

export default class AppBuildInformation extends Component<ComponentProps, ComponentState> {
    private _isMounted: boolean;

    constructor(props: ComponentProps) {
        super(props);
        this._isMounted = false;

        this.state = {};

        this.loadServerApplicationDetails = this.loadServerApplicationDetails.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadServerApplicationDetails();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadServerApplicationDetails() {
        getServerApplicationDetails({
            onSuccess: details => {
                if (!this._isMounted) return;
                this.setState({serverApplicationDetails: details})
            }
        })
    }

    render() {
        const environment = process.env.NODE_ENV === "production" ? undefined : process.env.NODE_ENV;
        return <div className={"AppBuildInformation"}>
            Build: {process.env.REACT_APP_VERSION} {environment === undefined ? undefined : "- " + environment}<br/>
            Backend: {this.state.serverApplicationDetails === undefined ? "unknown" : this.state.serverApplicationDetails?.version}
        </div>;
    }
}
