import React, {Component} from 'react';

interface ComponentProps {
    active: boolean
    onClick: (e: React.MouseEvent) => void
    href?: string
}

interface ComponentState {
}

export default class SideBarItem extends Component<ComponentProps, ComponentState> {
    static defaultProps = {
        active: false,
        onClick: () => null,
    }

    constructor(props: ComponentProps) {
        super(props);

        this.state = {};
    }

    render() {
        return <a className={"SideBarItem " + (this.props.active ? "active" : "")}
                  href={this.props.href}
                  onClick={this.props.onClick}>
            {this.props.children}
        </a>;
    }
}