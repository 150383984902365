import Rollbar from "rollbar";
import auth from "./auth";

// See: https://docs.rollbar.com/docs/rollbarjs-configuration-reference#section-context
export const rollbar = new Rollbar({
    accessToken: '17fa183be9f84b3698849b64d3462dc7',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.NODE_ENV === "production",
    stackTraceLimit: 50,
    verbose: true,
    payload: {
        context: window.location.href,
        environment: process.env.NODE_ENV,
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: process.env.REACT_APP_VERSION,
            }
        },
        person: {
            isLoggedIn: auth.isLoggedIn,
            id: auth.user?.id,
            username: auth.user?.username,
            email: auth.user?.username,
        }
    },
})

const checkShouldRollbarBeEnabled = () => {
    const shouldRollbarBeEnabled = process.env.NODE_ENV !== "development";

    if (shouldRollbarBeEnabled) return;
    console.debug("Rollbar is disabled", {env: process.env.NODE_ENV});

    const rollbarLogLocal = (logFunction: (...data: any[]) => void, args: Rollbar.LogArgument[]): Rollbar.LogResult => {
        logFunction(args);
        return {uuid: ""};
    };

    rollbar.log = (...args: Rollbar.LogArgument[]): Rollbar.LogResult => rollbarLogLocal(console.log, args);
    rollbar.debug = (...args: Rollbar.LogArgument[]): Rollbar.LogResult => rollbarLogLocal(console.debug, args);
    rollbar.info = (...args: Rollbar.LogArgument[]): Rollbar.LogResult => rollbarLogLocal(console.info, args);
    rollbar.warning = (...args: Rollbar.LogArgument[]): Rollbar.LogResult => rollbarLogLocal(console.warn, args);
    rollbar.error = (...args: Rollbar.LogArgument[]): Rollbar.LogResult => rollbarLogLocal(console.error, args);
    rollbar.critical = (...args: Rollbar.LogArgument[]): Rollbar.LogResult => rollbarLogLocal(console.error, args);
};

checkShouldRollbarBeEnabled();