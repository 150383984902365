import React, {Component} from 'react';
import './ErrorBoundary.sass';
import {rollbar} from "../Rollbar";
import {AuthorizationError} from "../errors";
import {Icon} from "semantic-ui-react";

interface ComponentProps {
}

interface ComponentState {
    error: Error | null,
    errorInfo: React.ErrorInfo | null,
}

export default class ErrorBoundary extends Component<ComponentProps, ComponentState> {

    constructor(props: ComponentProps) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null,
        };

        this.reset = this.reset.bind(this);
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        rollbar.log("Caught ErrorBoundary error", error, errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })
    }

    reset() {
        this.setState({
            error: null,
            errorInfo: null,
        })
    }

    render() {
        if (!this.state.errorInfo) {
            return this.props.children;
        }

        if (this.state.error instanceof AuthorizationError) {
            return <div className={"ErrorBoundary AuthorizationError"}>
                <div>
                    <Icon name={"lock"} size={"massive"}/>
                    <h1>It appears that you do not have the right permissions to view this page...</h1>
                </div>
            </div>;
        }

        return <div className={"ErrorBoundary"}>
            <h1>Whoops, something went wrong</h1>
            <div className={"deathFace"}>X_x</div>

            <details style={{whiteSpace: 'pre-wrap'}}>
                <div className={"errorName"}>{this.state.error && this.state.error.toString()}</div>

                <div className={"code"}>
                    {this.state.errorInfo.componentStack.trim().split('\n').map((it, i) =>
                        <div className={"line"} key={i}>
                            <span className={"lineIndex"}>{i + 1}</span>
                            {it}
                        </div>)}
                </div>
            </details>
        </div>;
    }
}
