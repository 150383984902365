import {AccessRequest} from "./modules/accessRequests/models";
import {User} from "./modules/userManagement/models";
import {Schedule} from "./modules/schedules/models";
import {config} from "./config";
import {AbcMelody, AbcSubMelody, Song, SongBundle, SongVerse} from "./modules/songs/models";
import {Document, DocumentGroup} from "./modules/documents/models";

const apiHostUrl = config.apiHostUrl;
export const apiBaseUrl = `${apiHostUrl}/api/v1`;

const get = (url: string) => fetch(url, {
    method: "GET",
    credentials: 'include',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const post = (url: string, data: any = "") => fetch(url, {
    method: "POST",
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    // mode: 'no-cors',
    body: JSON.stringify(data)
});

const put = (url: string, data: any = "") => fetch(url, {
    method: "PUT",
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
});

const remove = (url: string, data: any = "") => fetch(url, {
    method: "DELETE",
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
});

const upload = (url: string, data: FormData) => fetch(url, {
    method: "POST",
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
        // 'Content-Type': 'multipart/form-data'
    },
    // mode: 'no-cors',
    body: data
});

// eslint-disable-next-line no-unused-vars
export const throwErrorsIfNotOk = (response: Response) => {
    if (response.status === 404) {
        throw Error(`(${response.status}): ${response.statusText}`);
    } else if (!response.ok) {
        throw Error(`Request failed: ${response.status} ${response.statusText}`);
    }
    return response;
}

export const api = {
    server: {
        applicationDetails: () => get(`${apiBaseUrl}/system/application`)
    },

    auth: {
        login: (provider: string) => window.location.href = `${apiHostUrl}/login/${provider}`,
        logout: () => window.location.href = `${apiHostUrl}/logout`,

        users: {
            me: () => get(`${apiBaseUrl}/auth/users/me`),
            invite: (email: string) => post(`${apiBaseUrl}/auth/users/invite`, email),
            list: () => get(`${apiBaseUrl}/auth/users`),
            get: (id: number) => get(`${apiBaseUrl}/auth/users/${id}`),
            update: (id: number, user: User) => put(`${apiBaseUrl}/auth/users/${id}`, user),
            delete: (id: number) => remove(`${apiBaseUrl}/auth/users/${id}`),
            search: (query: string, page = 0, page_size = 50) =>
                get(`${apiBaseUrl}/auth/users?query=${query}&page=${page}&page_size=${page_size}`),
        },

        accessRequests: {
            list: () => get(`${apiBaseUrl}/auth/application/request-access`),
            pending: (page_size: number, page: number) => get(`${apiBaseUrl}/auth/application/request-access/pending?page_size=${page_size}&page=${page}`),
            get: (id: number) => get(`${apiBaseUrl}/auth/application/request-access/${id}`),
            update: (id: number, request: AccessRequest) => put(`${apiBaseUrl}/auth/application/request-access/${id}`, request),
            delete: (id: number) => remove(`${apiBaseUrl}/auth/application/request-access/${id}`),
            searchByClient: (query: string, page = 0, page_size = 50) =>
                get(`${apiBaseUrl}/auth/application/request-access?query=${query}&page=${page}&page_size=${page_size}&order=DESC`),
            searchByIp: (query: string, page = 0, page_size = 50) =>
                get(`${apiBaseUrl}/auth/application/request-access/listByIp?query=${query}&page=${page}&page_size=${page_size}&order=DESC`),
            client: (clientName: string, page = 0, page_size = 50) =>
                get(`${apiBaseUrl}/auth/application/request-access/client/${clientName}?page=${page}&page_size=${page_size}&order=DESC`),
            remoteHost: (remoteHost: string, page = 0, page_size = 50) =>
                get(`${apiBaseUrl}/auth/application/request-access/remoteHost/${remoteHost}?page=${page}&page_size=${page_size}&order=DESC`),
        }
    },

    schedules: {
        list: (loadItems?: boolean, loadVerses?: boolean) =>
            get(`${apiBaseUrl}/schedules?loadItems=${loadItems ? "true" : "false"}&loadVerses=${loadVerses ? "true" : "false"}`),
        get: (id: number, loadItems?: boolean, loadVerses?: boolean) =>
            get(`${apiBaseUrl}/schedules/${id}?loadItems=${loadItems ? "true" : "false"}&loadVerses=${loadVerses ? "true" : "false"}`),
        getWithItems: (id: number, loadVerses?: boolean) =>
            get(`${apiBaseUrl}/schedules/${id}?loadItems=true&loadVerses=${loadVerses ? "true" : "false"}`),
        create: (obj: Schedule) => post(`${apiBaseUrl}/schedules`, obj),
        update: (id: number, obj: Schedule) => put(`${apiBaseUrl}/schedules/${id}`, obj),
        delete: (id: number) => remove(`${apiBaseUrl}/schedules/${id}`),
        importers: () => get(`${apiBaseUrl}/schedules/import/importers`),
        import: (importer: string, data: FormData) => upload(`${apiBaseUrl}/schedules/import?importer=${importer}`, data),
        exporters: () => get(`${apiBaseUrl}/schedules/export/exporters`),
        export: (id: number, exporter: string) => `${apiBaseUrl}/schedules/export/${id}?exporter=${exporter}`,
        search: (query: string, page = 0, page_size = 50,
                 loadItems = false, loadVerses = false) =>
            get(`${apiBaseUrl}/schedules?query=${query}&page=${page}&page_size=${page_size}` +
                `&loadItems=${loadItems ? "true" : "false"}` +
                `&loadVerses=${loadVerses ? "true" : "false"}`),
    },

    songBundles: {
        list: (loadSongs?: boolean, loadVerses?: boolean, showInvisible = false, loadAbcMelodies: boolean = true) =>
            get(`${apiBaseUrl}/songs/bundles?loadSongs=${loadSongs ? "true" : "false"}` +
                `&loadVerses=${loadVerses ? "true" : "false"}` +
                `&loadAbcMelodies=${loadAbcMelodies ? "true" : "false"}` +
                `&showInvisible=${showInvisible ? "true" : "false"}`),
        get: (id: number, loadSongs?: boolean, loadVerses?: boolean, loadAbcMelodies: boolean = true) =>
            get(`${apiBaseUrl}/songs/bundles/${id}` +
                `?loadSongs=${loadSongs ? "true" : "false"}` +
                `&loadAbcMelodies=${loadAbcMelodies ? "true" : "false"}` +
                `&loadVerses=${loadVerses ? "true" : "false"}`),
        getWithSongs: (id: number, loadVerses?: boolean) =>
            get(`${apiBaseUrl}/songs/bundles/${id}?loadSongs=true&loadVerses=${loadVerses ? "true" : "false"}`),
        create: (obj: SongBundle) => post(`${apiBaseUrl}/songs/bundles`, obj),
        update: (id: number, obj: SongBundle) => put(`${apiBaseUrl}/songs/bundles/${id}`, obj),
        delete: (id: number) => remove(`${apiBaseUrl}/songs/bundles/${id}`),
        search: (query: string, page = 0, page_size = 50,
                 fieldLanguages: Array<String> = [],
                 loadSongs = false,
                 loadVerses = false,
                 showInvisible = false) =>
            get(`${apiBaseUrl}/songs/bundles?query=${query}&page=${page}&page_size=${page_size}` +
                `&fieldLanguages=${fieldLanguages.join(",")}` +
                `&loadSongs=${loadSongs ? "true" : "false"}` +
                `&loadVerses=${loadVerses ? "true" : "false"}` +
                `&showInvisible=${showInvisible ? "true" : "false"}`),
    },

    songs: {
        list: (loadSongBundle?: boolean, loadVerses?: boolean, page = 0, page_size = 50, loadAbcMelodies: boolean = true) =>
            get(`${apiBaseUrl}/songs?loadSongBundle=${loadSongBundle ? "true" : "false"}` +
                `&loadVerses=${loadVerses ? "true" : "false"}` +
                `&loadAbcMelodies=${loadAbcMelodies ? "true" : "false"}` +
                `&page=${page}&page_size=${page_size}`),
        get: (id: number, loadSongBundle?: boolean, loadVerses?: boolean, loadAbcMelodies: boolean = true) =>
            get(`${apiBaseUrl}/songs/${id}` +
                `?loadSongBundle=${loadSongBundle ? "true" : "false"}` +
                `&loadAbcMelodies=${loadAbcMelodies ? "true" : "false"}` +
                `&loadVerses=${loadVerses ? "true" : "false"}`),
        getWithVerses: (id: number, loadSongBundle?: boolean, loadAbcMelodies: boolean = true) =>
            get(`${apiBaseUrl}/songs/${id}` +
                `?loadVerses=true` +
                `&loadSongBundle=${loadSongBundle ? "true" : "false"}` +
                `&loadAbcMelodies=${loadAbcMelodies ? "true" : "false"}`),
        create: (obj: Song) => post(`${apiBaseUrl}/songs`, obj),
        update: (obj: Song) => put(`${apiBaseUrl}/songs/${obj.id}`, obj),
        delete: (id: number) => remove(`${apiBaseUrl}/songs/${id}`),
        importers: () => get(`${apiBaseUrl}/songs/import/importers`),
        import: (importer: string, data: FormData) => upload(`${apiBaseUrl}/songs/import?importer=${importer}`, data),
        search: (query: string, page = 0, page_size = 50,
                 fieldSongBundles: Array<string> = [],
                 loadSongBundle = false, loadVerses = false,
                 isRegex = false,
                 loadAbcMelodies: boolean = true) =>
            get(`${apiBaseUrl}/songs?query=${query}&page=${page}&page_size=${page_size}` +
                `&fieldSongBundles=${fieldSongBundles.join(",")}` +
                `&loadSongBundle=${loadSongBundle ? "true" : "false"}` +
                `&loadVerses=${loadVerses ? "true" : "false"}` +
                `&loadAbcMelodies=${loadAbcMelodies ? "true" : "false"}` +
                `&regex=${isRegex ? "true" : "false"}`),

        verses: {
            create: (obj: SongVerse) => post(`${apiBaseUrl}/songs/verses`, obj),
            update: (obj: SongVerse) => put(`${apiBaseUrl}/songs/verses/${obj.id}`, obj),
            delete: (id: number) => remove(`${apiBaseUrl}/songs/verses/${id}`),

            melodies: {
                create: (obj: AbcSubMelody) => post(`${apiBaseUrl}/songs/verses/melodies`, obj),
                update: (obj: AbcSubMelody) => put(`${apiBaseUrl}/songs/verses/melodies/${obj.id}`, obj),
                delete: (obj: { id: number }) => remove(`${apiBaseUrl}/songs/verses/melodies/${obj.id}`),
            },
        },

        melodies: {
            create: (obj: AbcMelody) => post(`${apiBaseUrl}/songs/melodies`, obj),
            update: (obj: AbcMelody) => put(`${apiBaseUrl}/songs/melodies/${obj.id}`, obj),
            delete: (obj: { id: number }) => remove(`${apiBaseUrl}/songs/melodies/${obj.id}`),
            generateMp3: (obj: { uuid: string }) => `${apiBaseUrl}/songs/audio/generate/${obj.uuid}`,
        },

        audiofiles: {
            all: (obj: { id: number }) => get(`${apiBaseUrl}/songs/${obj.id}/audio`),
            upload: (data: FormData) => upload(`${apiBaseUrl}/songs/audio/upload`, data),
            delete: (obj: { id: number }) => remove(`${apiBaseUrl}/songs/audio/${obj.id}`),
        },
    },

    documents: {
        list: (loadParent?: boolean, loadContent?: boolean, page = 0, page_size = 50,) =>
            get(`${apiBaseUrl}/documents?loadParent=${loadParent ? "true" : "false"}` +
                `&loadContent=${loadContent ? "true" : "false"}` +
                `&page=${page}&page_size=${page_size}`),
        get: (id: number, loadParent?: boolean, loadContent?: boolean) =>
            get(`${apiBaseUrl}/documents/${id}?loadParent=${loadParent ? "true" : "false"}` +
                `&loadContent=${loadContent ? "true" : "false"}`),
        create: (obj: Document) => post(`${apiBaseUrl}/documents`, obj),
        update: (id: number, obj: Document) => put(`${apiBaseUrl}/documents/${id}`, obj),
        delete: (id: number) => remove(`${apiBaseUrl}/documents/${id}`),
        search: (query: string, page = 0, page_size = 50,
                 loadParent = false,
                 loadContent = false,
                 isRegex = false) =>
            get(`${apiBaseUrl}/documents?query=${query}&page=${page}&page_size=${page_size}` +
                `&loadParent=${loadParent ? "true" : "false"}` +
                `&loadContent=${loadContent ? "true" : "false"}` +
                `&regex=${isRegex ? "true" : "false"}`),

        groups: {
            list: (loadGroups?: boolean,
                   loadItems?: boolean,
                   loadContent?: boolean,
                   showInvisible = false,
                   page = 0, page_size = 50,) =>
                get(`${apiBaseUrl}/documents/groups?loadGroups=${loadGroups ? "true" : "false"}` +
                    `&loadItems=${loadItems ? "true" : "false"}` +
                    `&loadContent=${loadContent ? "true" : "false"}` +
                    `&showInvisible=${showInvisible ? "true" : "false"}` +
                    `&page=${page}&page_size=${page_size}`),
            root: (loadGroups?: boolean,
                   loadItems?: boolean,
                   loadContent?: boolean,
                   showInvisible = false,
                   page = 0, page_size = 50,) =>
                get(`${apiBaseUrl}/documents/groups/root?loadGroups=${loadGroups ? "true" : "false"}` +
                    `&loadItems=${loadItems ? "true" : "false"}` +
                    `&loadContent=${loadContent ? "true" : "false"}` +
                    `&showInvisible=${showInvisible ? "true" : "false"}` +
                    `&page=${page}&page_size=${page_size}`),
            get: (id: number, loadGroups?: boolean,
                  loadItems?: boolean,
                  loadContent?: boolean,
                  showInvisible = false) =>
                get(`${apiBaseUrl}/documents/groups/${id}?loadGroups=${loadGroups ? "true" : "false"}` +
                    `&loadItems=${loadItems ? "true" : "false"}` +
                    `&loadContent=${loadContent ? "true" : "false"}` +
                    `&showInvisible=${showInvisible ? "true" : "false"}`),
            create: (obj: DocumentGroup) => post(`${apiBaseUrl}/documents/groups`, obj),
            update: (id: number, obj: DocumentGroup) => put(`${apiBaseUrl}/documents/groups/${id}`, obj),
            delete: (id: number) => remove(`${apiBaseUrl}/documents/groups/${id}`),
            search: (query: string, page = 0, page_size = 50,
                     loadGroups = false,
                     loadItems = false,
                     loadContent = false,
                     showInvisible = false,
                     isRegex = false) =>
                get(`${apiBaseUrl}/documents/groups?query=${query}&page=${page}&page_size=${page_size}` +
                    `&loadGroups=${loadGroups ? "true" : "false"}` +
                    `&loadItems=${loadItems ? "true" : "false"}` +
                    `&loadContent=${loadContent ? "true" : "false"}` +
                    `&showInvisible=${showInvisible ? "true" : "false"}` +
                    `&regex=${isRegex ? "true" : "false"}`),
        }
    },

    logging: {
        activity: {
            get: (id: number, loadChildren: boolean, loadParent: boolean) =>
                get(`${apiBaseUrl}/logging/activity/${id}?loadChildren=${loadChildren}&loadParent=${loadParent}`),

            all: {
                recentlyViewed: (page_size: number, page: number, loadChildren: boolean) =>
                    get(`${apiBaseUrl}/logging/activity/all/recently-viewed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true&onlyParents=true`),
                recentlyChanged: (page_size: number, page: number, loadChildren: boolean) =>
                    get(`${apiBaseUrl}/logging/activity/all/recently-changed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true&onlyParents=true`),
            },

            schedules: {
                recentlyViewed: (page_size: number, page: number, loadChildren: boolean) =>
                    get(`${apiBaseUrl}/logging/activity/schedules/recently-viewed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),
                recentlyChanged: (page_size: number, page: number, loadChildren: boolean) =>
                    get(`${apiBaseUrl}/logging/activity/schedules/recently-changed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),

                items: {
                    recentlyViewed: (page_size: number, page: number, loadChildren: boolean) =>
                        get(`${apiBaseUrl}/logging/activity/schedules/items/recently-viewed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),
                    recentlyChanged: (page_size: number, page: number, loadChildren: boolean) =>
                        get(`${apiBaseUrl}/logging/activity/schedules/items/recently-changed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),

                    verses: {
                        recentlyViewed: (page_size: number, page: number, loadChildren: boolean) =>
                            get(`${apiBaseUrl}/logging/activity/schedules/items/verses/recently-viewed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),
                        recentlyChanged: (page_size: number, page: number, loadChildren: boolean) =>
                            get(`${apiBaseUrl}/logging/activity/schedules/items/verses/recently-changed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),
                    },
                },
            },
            accessRequests: {
                recentlyViewed: (page_size: number, page: number, loadChildren: boolean) =>
                    get(`${apiBaseUrl}/logging/activity/access-requests/recently-viewed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),
                recentlyChanged: (page_size: number, page: number, loadChildren: boolean) =>
                    get(`${apiBaseUrl}/logging/activity/access-requests/recently-changed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),
            },
            users: {
                recentlyViewed: (page_size: number, page: number, loadChildren: boolean) =>
                    get(`${apiBaseUrl}/logging/activity/users/recently-viewed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),
                recentlyChanged: (page_size: number, page: number, loadChildren: boolean) =>
                    get(`${apiBaseUrl}/logging/activity/users/recently-changed?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),
            },

            accessRequest: {
                get: (id: number, page_size: number, page: number, loadChildren: boolean) =>
                    get(`${apiBaseUrl}/logging/activity/application/${id}?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),
            },
            user: {
                get: (id: number, page_size: number, page: number, loadChildren: boolean) =>
                    get(`${apiBaseUrl}/logging/activity/user/${id}?page_size=${page_size}&page=${page}&loadChildren=${loadChildren}&loadWhatObject=true`),
            },
        },
    },
};
