import React from "react";
import {View, ViewList} from "./models";
import {UserRole} from "./auth";

export const HomePage = React.lazy(() => import("./modules/homePage/HomePage"));
export const Dashboard = React.lazy(() => import("./modules/dashboard/Dashboard"));
export const AccessRequests = React.lazy(() => import("./modules/accessRequests/AccessRequests"));
export const UserManagement = React.lazy(() => import("./modules/userManagement/UserManagement"));
export const LoginScreen = React.lazy(() => import("./modules/login/LoginScreen"));
export const NotFoundPage = React.lazy(() => import("./components/NotFoundPage"));
export const Schedules = React.lazy(() => import("./modules/schedules/Schedules"));
export const ScheduleEditor = React.lazy(() => import("./modules/schedules/editor/ScheduleEditor"));
export const ScheduleViewer = React.lazy(() => import("./modules/schedules/editor/ScheduleViewer"));
export const Songs = React.lazy(() => import("./modules/songs/Songs"));
export const SongBundleViewer = React.lazy(() => import("./modules/songs/songBundles/viewer/SongBundleViewer"));
export const SongBundleEditor = React.lazy(() => import("./modules/songs/songBundles/songBundleEditor/SongBundleEditor"));
export const Documents = React.lazy(() => import("./modules/documents/Documents"));
export const PrivacyPolicy = React.lazy(() => import("./modules/privacypolicy/PrivacyPolicy"));

const views = {
    HomePage: new View({
        url: "/",
        icon: "home",
        exactPath: true,
        showInMenu: false,
        needsAuthentication: false,
        component: HomePage,
    }),
    Dashboard: new View({
        url: "/dashboard",
        title: "Dashboard",
        icon: "home",
        needsAuthentication: true,
        component: Dashboard,
    }),
    AccessRequests: new View({
        url: "/access-requests",
        title: "Access Requests",
        icon: "unlock alternate",
        component: AccessRequests,
        needsAuthentication: true,
        permissions: [UserRole.ACCESS_REQUESTS_VIEW],
        views: {
            Single: new View({
                url: "/:id",
                showInMenu: false,
                needsAuthentication: true,
                component: AccessRequests,
                permissions: [UserRole.ACCESS_REQUESTS_VIEW],
            }),
        },
    }),
    UserManagement: new View({
        url: "/user-management",
        title: "Users",
        icon: "users",
        component: UserManagement,
        needsAuthentication: true,
        permissions: [UserRole.USERS_VIEW],
        views: {
            Invite: new View({
                url: "/invite",
                showInMenu: false,
                needsAuthentication: true,
                component: UserManagement,
                permissions: [UserRole.USERS_CHANGE],
            }),
            Single: new View({
                url: "/:id",
                showInMenu: false,
                needsAuthentication: true,
                component: UserManagement,
                permissions: [UserRole.USERS_VIEW],
            }),
        },
    }),
    Schedules: new View({
        url: "/schedules",
        title: "Schedules",
        icon: "list",
        component: Schedules,
        needsAuthentication: true,
        permissions: [UserRole.SCHEDULES_VIEW],
        views: {
            New: new View({
                url: "/edit",
                showInMenu: false,
                exactPath: true,
                needsAuthentication: true,
                component: ScheduleEditor,
                permissions: [UserRole.SCHEDULES_CHANGE],
            }),
            Edit: new View({
                url: "/edit/:id",
                showInMenu: false,
                needsAuthentication: true,
                component: ScheduleEditor,
                permissions: [UserRole.SCHEDULES_CHANGE],
            }),
            Import: new View({
                url: "/import",
                showInMenu: false,
                exactPath: true,
                needsAuthentication: true,
                component: Schedules,
                permissions: [UserRole.SCHEDULES_CHANGE],
            }),
            Export: new View({
                url: "/export/:id",
                showInMenu: false,
                needsAuthentication: true,
                component: Schedules,
                permissions: [UserRole.SCHEDULES_VIEW],
            }),
            Single: new View({
                url: "/view/:id",
                showInMenu: false,
                needsAuthentication: true,
                component: ScheduleViewer,
                permissions: [UserRole.SCHEDULES_VIEW],
            }),
        },
    }),
    Songs: new View({
        url: "/songs",
        title: "Songs",
        icon: "music",
        component: Songs,
        needsAuthentication: true,
        permissions: [UserRole.SONGS_VIEW],
        views: {
            Import: new View({
                url: "/import",
                showInMenu: false,
                exactPath: true,
                needsAuthentication: true,
                component: Songs,
                permissions: [UserRole.SONGS_CHANGE, UserRole.SONG_BUNDLES_CHANGE],
            }),
            Single: new View({
                url: "/:id",
                showInMenu: false,
                needsAuthentication: true,
                component: Songs,
                permissions: [UserRole.SONGS_VIEW],
            }),
            New: new View({
                url: "/new",
                showInMenu: false,
                needsAuthentication: true,
                component: Songs,
                permissions: [UserRole.SONGS_CHANGE],
            }),
            SongBundleView: new View({
                url: "/bundle/:id",
                showInMenu: false,
                needsAuthentication: true,
                component: SongBundleViewer,
                permissions: [UserRole.SONG_BUNDLES_VIEW],
            }),
            SongBundleNew: new View({
                url: "/bundle/edit",
                showInMenu: false,
                exactPath: true,
                needsAuthentication: true,
                component: SongBundleEditor,
                permissions: [UserRole.SONG_BUNDLES_CHANGE],
            }),
            SongBundleEdit: new View({
                url: "/bundle/edit/:id",
                showInMenu: false,
                needsAuthentication: true,
                component: SongBundleEditor,
                permissions: [UserRole.SONG_BUNDLES_CHANGE],
            }),
        },
    }),
    Documents: new View({
        url: "/documents",
        title: "Documents",
        icon: "folder open",
        component: Documents,
        needsAuthentication: true,
        permissions: [UserRole.DOCUMENT_GROUPS_VIEW],
        views: {
            NewGroup: new View({
                url: "/groups/create",
                showInMenu: false,
                needsAuthentication: true,
                component: Documents,
                permissions: [UserRole.DOCUMENT_GROUPS_CHANGE],
            }),
            NewSubGroup: new View({
                url: "/groups/:id/creategroup",
                showInMenu: false,
                needsAuthentication: true,
                component: Documents,
                permissions: [UserRole.DOCUMENT_GROUPS_CHANGE],
            }),
            SingleGroup: new View({
                url: "/groups/:id",
                showInMenu: false,
                needsAuthentication: true,
                component: Documents,
                permissions: [UserRole.DOCUMENT_GROUPS_VIEW, UserRole.DOCUMENTS_VIEW],
            }),

            NewDocument: new View({
                url: "/groups/:id/createdocument",
                showInMenu: false,
                needsAuthentication: true,
                component: Documents,
                permissions: [UserRole.DOCUMENTS_CHANGE],
            }),
            SingleDocument: new View({
                url: "/:id",
                showInMenu: false,
                needsAuthentication: true,
                component: Documents,
                permissions: [UserRole.DOCUMENTS_VIEW],
            }),
        },
    }),
    LoginScreen: new View({
        url: "/login",
        title: "Log in",
        icon: "user",
        showInMenu: false,
        needsAuthentication: false,
        component: LoginScreen,
    }),
    PrivacyPolicy: new View({
        url: "/privacy",
        icon: "home",
        exactPath: true,
        showInMenu: false,
        needsAuthentication: false,
        component: PrivacyPolicy,
    }),
    NotFoundPage: new View({
        url: "*",
        title: "Not found",
        showInMenu: false,
        needsAuthentication: false,
        component: NotFoundPage,
    }),
}

export default views;

function generateFullPaths(views: ViewList, basePath: string) {
    Object.values(views).forEach(view => {
        view.fullPath = basePath + view.url;
        generateFullPaths(view.views, view.fullPath);
    })
}

generateFullPaths(views, "");

export function createUrl(view: View, params?: Object): string | undefined {
    if (params === undefined) {
        return view.fullPath;
    }

    let parsedUrl = view.fullPath;
    Object.entries(params).forEach(([key, value]) => {
        const regex = new RegExp(":" + key + "(\\W|$)", "g");
        parsedUrl = parsedUrl.replace(regex, value + "$1")
    })
    return parsedUrl;
}
