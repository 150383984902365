import React from "react";
import './EnvironmentBanner.sass';

type Props = {}

const EnvironmentBanner: React.FC<Props> = ({}) => {
    if (process.env.NODE_ENV === "production") return null;

    return <div className={"EnvironmentBanner"}>
        <span className={"name"}>
            {process.env.NODE_ENV}
        </span>
    </div>;
}

export default EnvironmentBanner;
